import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT
class IlesService {

    getIslandByName(name: string) {
        return axios.get(API_URL + "/api/v1/public/iles?critere=" + name)
    }
    getIslandVoyage() {
        return axios.get(API_URL + "/api/v1/public/iles/desservies-voyages?dateDebut=2024-08-12&dateFin=2024-08-31")
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new IlesService();